var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "body add-line" }, [
    _c(
      "div",
      { staticClass: "form" },
      [
        _c(
          "el-form",
          {
            ref: "addForm",
            staticClass: "demo-form dialog-form dialog-form-inline",
            attrs: {
              model: _vm.addForm,
              rules: _vm.rules,
              "label-width": "102px",
              "label-position": "left"
            }
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "线路名称：", prop: "companyName" } },
              [
                _c("el-input", {
                  attrs: { type: "text", disabled: "" },
                  model: {
                    value: _vm.addForm.companyName,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.addForm,
                        "companyName",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "addForm.companyName"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "线路类型：", prop: "lineType" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: { clearable: "", placeholder: "请选择线路类型" },
                    model: {
                      value: _vm.addForm.lineType,
                      callback: function($$v) {
                        _vm.$set(_vm.addForm, "lineType", $$v)
                      },
                      expression: "addForm.lineType"
                    }
                  },
                  _vm._l(_vm.lineTypeList, function(item) {
                    return _c("el-option", {
                      key: item.dictCode,
                      attrs: { label: item.dictValue, value: item.dictCode }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "线路编码：", prop: "lineCode" } },
              [
                _c("el-input", {
                  attrs: { type: "text" },
                  model: {
                    value: _vm.addForm.lineCode,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.addForm,
                        "lineCode",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "addForm.lineCode"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "所在城市：", prop: "city" } },
              [
                _c("el-cascader", {
                  attrs: { options: _vm.cityList, clearable: "" },
                  model: {
                    value: _vm.addForm.city,
                    callback: function($$v) {
                      _vm.$set(_vm.addForm, "city", $$v)
                    },
                    expression: "addForm.city"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "主场：", prop: "masterPark" } },
              [
                _c("selectYard", {
                  attrs: {
                    city: _vm.addForm.city,
                    choosedYard: _vm.choosedMaster
                  },
                  on: { getData: _vm.getMainYard }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "副场：", prop: "slaverPark" } },
              [
                _c("selectYard", {
                  attrs: {
                    city: _vm.addForm.city,
                    choosedYard: _vm.choosedSlaver
                  },
                  on: { getData: _vm.getViceYard }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "路队长：", prop: "captainId" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: {
                      clearable: "",
                      filterable: "",
                      placeholder: "请选择路队长"
                    },
                    model: {
                      value: _vm.addForm.captainId,
                      callback: function($$v) {
                        _vm.$set(_vm.addForm, "captainId", $$v)
                      },
                      expression: "addForm.captainId"
                    }
                  },
                  _vm._l(_vm.uerList, function(item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.userName, value: item.id }
                    })
                  }),
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c("p", { staticClass: "from-title form-title2 styleflex" }, [
          _vm._v("线路站点设置")
        ]),
        _c(
          "el-button",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.addForm.lineType !== 1,
                expression: "addForm.lineType !== 1"
              }
            ],
            attrs: { type: "primary", size: "small" },
            on: { click: _vm.changeLine }
          },
          [_vm._v("切换上下行")]
        ),
        _c("div", { staticClass: "line-container" }, [
          _c(
            "div",
            { staticClass: "up-down-line" },
            [
              _c("p", { staticClass: "from-title form-title3" }, [
                _vm._v("上行")
              ]),
              _c("el-card", { staticClass: "box-card" }, [
                _c(
                  "div",
                  {
                    staticClass: "clearfix",
                    attrs: { slot: "header" },
                    slot: "header"
                  },
                  [
                    _c("span", [_vm._v("选择站点：")]),
                    _c("selectStation", {
                      attrs: { city: _vm.addForm.city },
                      on: { getData: _vm.getUpStation }
                    })
                  ],
                  1
                ),
                _c("div", { ref: "container", staticClass: "tag-box" }, [
                  _vm.upStationList.length > 0
                    ? _c(
                        "div",
                        { staticClass: "tag-section" },
                        _vm._l(_vm.upStationList, function(tag, index) {
                          return _c(
                            "el-tag",
                            {
                              directives: [
                                {
                                  name: "dragging",
                                  rawName: "v-dragging",
                                  value: {
                                    item: tag,
                                    list: _vm.upStationList,
                                    group: "tag1"
                                  },
                                  expression:
                                    "{ item: tag, list: upStationList, group: 'tag1' }"
                                }
                              ],
                              key: index,
                              attrs: { closable: "" },
                              on: {
                                close: function($event) {
                                  return _vm.removeUpStation(index)
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(index + 1) +
                                  ". " +
                                  _vm._s(tag.stationName) +
                                  " "
                              )
                            ]
                          )
                        }),
                        1
                      )
                    : _c("div", { staticClass: "tag-section" }, [
                        _vm._v("暂无数据")
                      ])
                ])
              ])
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "up-down-line",
              staticStyle: { "margin-left": "2%" }
            },
            [
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.addForm.lineType !== 1,
                      expression: "addForm.lineType !== 1"
                    }
                  ],
                  staticClass: "from-title form-title3"
                },
                [_vm._v("下行")]
              ),
              _c(
                "el-card",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.addForm.lineType !== 1,
                      expression: "addForm.lineType !== 1"
                    }
                  ],
                  staticClass: "box-card"
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      attrs: { slot: "header" },
                      slot: "header"
                    },
                    [
                      _c("span", [_vm._v("选择站点：")]),
                      _c("selectStation", {
                        attrs: { city: _vm.addForm.city },
                        on: { getData: _vm.getDownStation }
                      })
                    ],
                    1
                  ),
                  _c("div", { ref: "container", staticClass: "tag-box" }, [
                    _vm.downStationList.length > 0
                      ? _c(
                          "div",
                          { staticClass: "tag-section" },
                          _vm._l(_vm.downStationList, function(tag, index) {
                            return _c(
                              "el-tag",
                              {
                                directives: [
                                  {
                                    name: "dragging",
                                    rawName: "v-dragging",
                                    value: {
                                      item: tag,
                                      list: _vm.downStationList,
                                      group: "tag"
                                    },
                                    expression:
                                      "{ item: tag, list: downStationList, group: 'tag' }"
                                  }
                                ],
                                key: index,
                                attrs: { closable: "" },
                                on: {
                                  close: function($event) {
                                    return _vm.removeDownStation(index)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(index + 1) +
                                    ". " +
                                    _vm._s(tag.stationName) +
                                    " "
                                )
                              ]
                            )
                          }),
                          1
                        )
                      : _c("div", { staticClass: "tag-section" }, [
                          _vm._v("暂无数据")
                        ])
                  ])
                ]
              )
            ],
            1
          )
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }