<template>
  <div class="line-management" ref="lineManagement">
    <div class="function-buttons" ref="functionButtons"></div>
    <div class="form-area" ref="form">
      <el-form
        :inline="true"
        :model="form"
        class="demo-form-inline"
        label-position="left"
        label-width="72px"
      >
        <el-form-item label="线路名称：">
          <el-select
            class="select-width"
            v-model="form.companyIds"
            multiple
            filterable
            remote
            collapse-tags
            clearable
            placeholder="请输入线路名称"
            :remote-method="remoteMethod"
            :loading="loading"
            :multiple-limit="20"
            :reserve-keyword="false"
          >
            <el-option
              v-for="item in options"
              :key="item.companyId"
              :label="item.companyName"
              :value="item.companyId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所在区域：">
          <el-cascader
            :options="cityList"
            clearable
            v-model="form.city"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="线路类型：">
          <el-select
            v-model="form.lineType"
            clearable
            placeholder="请选择线路类型"
          >
            <el-option
              v-for="item in lineTypeList"
              :label="item.dictValue"
              :value="item.dictCode"
              :key="item.dictCode"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label-width="0">
          <el-button
            type="primary"
            size="small"
            @click="onSearch"
            :loading="searchLoading"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="tableData" stripe :height="tableHeight">
      <el-table-column label="序号" type="index" width="60"></el-table-column>
      <el-table-column prop="parentName" label="所属公司"></el-table-column>
      <el-table-column prop="companyName" label="线路名称"></el-table-column>
      <el-table-column prop="cityStr" label="所在城市"></el-table-column>
      <el-table-column prop="lineType" label="线路类型">
        <template slot-scope="scope">
          {{ lineTypeObj[Number(scope.row.lineType)] }}
        </template>
      </el-table-column>
      <el-table-column prop="captainName" label="路队长"></el-table-column>
      <el-table-column prop="status" label="状态">
        <template slot-scope="scope">
          {{ scope.row.status === 0 ? "正常" : "停运" }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="140">
        <template slot-scope="scope">
          <el-button
            @click="onUpdateLine(scope.row)"
            type="text"
            size="small"
            sort="primary"
            v-if="nowMenuList.indexOf('编辑') >= 0"
            >编辑</el-button
          >
          <el-button
            type="text"
            size="small"
            :sort="scope.row.status === 0 ? 'danger' : 'primary'"
            @click="onDisableLine(scope.row)"
            v-if="
              scope.row.status === 0
                ? nowMenuList.indexOf('停运') >= 0
                : nowMenuList.indexOf('启用') >= 0
            "
            >{{ scope.row.status === 0 ? "停运" : "启用" }}</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination" ref="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @current-change="onCurrentChange"
        @size-change="onSizeChange"
      ></el-pagination>
    </div>

    <!-- 编辑 -->
    <el-dialog
      :title="title"
      v-if="dialogVisible"
      :visible.sync="dialogVisible"
      @close="onDialogClose"
      width="70%"
      custom-class="add-line-dialog"
    >
      <addLine
        ref="addLine"
        :itemId="itemId"
        @close="onDialogClose"
        @onLoading="onLoading"
      />
      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="dialogVisible = false"
          >取消</el-button
        >
        <el-button
          type="primary"
          size="small"
          :loading="editLoading"
          @click="onSave"
          >保存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { formatDict } from '@/common/utils/index'
import { queryAreaList } from '@/api/lib/api.js'
import { queryLineDetailFuzzy } from '@/api/lib/refreshAlarm.js'

import addLine from './components/addLine'
import { mapGetters } from 'vuex'

import {
  getBusLineList,
  disableBusLine,
} from '@/api/lib/bus-api.js'


export default {
  name: 'lineManagement',
  components: {
    addLine
  },
  data () {
    return {
      tableHeight: 0,
      tableData: [],
      form: {
        lineName: null,
        city: null,
        currentPage: 1,
        pageSize: 10
      },
      total: 0,
      title: '',
      cityList: [],
      lineTypeList: [
        { dictValue: "单向环线", dictCode: 1 },
        { dictValue: "双向线路", dictCode: 2 },
        { dictValue: "双向环线", dictCode: 3 }
      ],
      lineTypeObj: {},
      dialogVisible: false,
      searchLoading: false,
      editLoading: false,
      options: [],
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      nowMenuList: 'nowMenuList'
    }),

  },
  methods: {
    // 计算表格高度
    computeHeight () {
      const wholeHeight = this.$refs.lineManagement.clientHeight
      const buttonsHeight = this.$refs.functionButtons.clientHeight
      const formHeight = this.$refs.form.clientHeight
      const paginationHeight = this.$refs.pagination.clientHeight
      this.tableHeight =
        wholeHeight -
        16 -
        buttonsHeight -
        16 -
        formHeight -
        paginationHeight -
        10 -
        16
    },

    onSearch () {
      this.searchLoading = true
      this.form.currentPage = 1
      this.form.pageSize = 10
      this.getListByField()
    },
    getListByField () {
      let data = { ...this.form }
      if (data.city) {
        data.city = data.city[1]
      }
      getBusLineList(data)
        .then((res) => {
          if (res.code === 1000) {
            this.tableData =
              res.data.list && res.data.list.length > 0
                ? res.data.list
                : []
            this.total = res.data.total

          } else {
            this.tableData = []
            this.total = 0
            this.$message.error(res.msg)
          }
          this.searchLoading = false
        })
        .catch(() => {
          this.searchLoading = false
        })
    },
    // 当前页码改变
    onCurrentChange (page) {
      this.form.currentPage = page
      this.getListByField()
    },
    // 当前条数改变
    onSizeChange (size) {
      this.form.pageSize = size
      this.form.currentPage = 1
      this.getListByField()
    },

    // 模糊搜索线路
    remoteMethod (query) {
      if (query !== '') {
        this.loading = true
        setTimeout(() => {
          this.loading = false
          let data = { lineName: query }
          queryLineDetailFuzzy(data).then((res) => {
            if (res.code === 1000) {
              this.options = res.data
            }
          })
        }, 200)
      } else {
        this.options = []
      }
    },

    // 编辑
    onUpdateLine (row) {
      this.title = '修改线路'
      this.itemId = row.companyId
      this.dialogVisible = true
    },
    //  禁用|启用 线路
    onDisableLine (row) {
      if (row.status === 0) {
        const h = this.$createElement
        this.$msgbox({
          title: '消息',
          type: 'warning',
          message: h('p', null, [h('span', null, '确认停运该线路？')]),
          showCancelButton: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        })
          .then(() => {
            disableBusLine({ id: row.detailId, status: 1 }).then((res) => {
              if (res.code === 1000) {
                this.$message.success('停运成功')
                row.status = 1
              } else {
                this.$message.error(res.msg)
              }
            })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              showClose: true,
              message: '已取消停运'
            })
          })
      } else {
        disableBusLine({ id: row.detailId, status: 0 }).then((res) => {
          if (res.code === 1000) {
            this.$message.success('启用成功')
            row.status = 0
          } else {
            this.$message.error(res.msg)
          }
        })
      }
    },

    // 关闭弹窗
    onDialogClose (flag) {
      this.dialogVisible = false
      if (flag) {
        this.getListByField()
      }
      this.editLoading = false
    },
    //  保存
    onSave () {
      this.$refs.addLine.onSave()
    },
    onLoading (flag) {
      this.editLoading = flag
    },
    // 关闭弹窗
    closeDialog (flag) {
      this.dialogVisible = false
      if (flag) {
        if (this.title.indexOf('新增') > -1) {
          this.onSearch()
        } else {
          this.getListByField()
        }
      }
    },



    // 查询省市接口
    getAreaList () {
      queryAreaList().then((res) => {
        if (res.code === 1000) {
          this.cityList = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    },

  },
  created () {
    this.getAreaList()

  },
  mounted () {
    this.$nextTick(() => {
      this.lineTypeObj = formatDict(this.lineTypeList)
      this.computeHeight()
      this.onSearch()
      window.addEventListener('resize', this.computeHeight, false)
    })
  },
  destroyed () {
    window.removeEventListener('resize', this.computeHeight)
  }
}
</script>

<style lang="scss" scoped>
.line-management {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  box-sizing: border-box;
  @include themify() {
  }
}

.theme-project-resourse {
  .line-management {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  }
}
</style>
