<template>
  <el-select
    ref="select_element"
    v-model="value"
    :clearable="clearable"
    @clear="resetForm"
    :filterable="searchable"
    :filter-method="filterStation"
    @change="handleSelect"
    placeholder="请输入"
    :loading="loading"
  >
    <el-option
      v-for="item in options"
      :key="item.id"
      :label="item.name"
      :value="item[label]"
    ></el-option>
  </el-select>
</template>

<script>
/**
 * 选择站点   模糊搜索 单选
 *
 */
import { querySiteFuzzy } from '@/api/lib/refreshAlarm.js'
export default {
  props: {
    label: {
      type: String,
      default: 'id'
    },
    /* 已选中站点 */
    choosedStation: {
      type: [Number, String],
    },
    /* 已选中list */
    stationList: {
      type: Array,
      default: () => {
        return []
      }
    },
    /**城市 联动 */
    city: {
      type: Array,
      default: () => {
        return []
      }
    },
    /* 可清空选项 */
    clearable: {
      type: Boolean,
      default: () => {
        return true
      }
    },
    /* 当前表格index */
    index: {
      type: Number,
    },

    searchable: {
      type: Boolean,
      default: () => {
        return true
      }
    },

  },
  created () { },

  data () {
    return {
      value: null, // 初始值
      options: [],
      loading: false
    }
  },

  methods: {
    /** 清空input */
    resetForm () {
      this.value = null
      this.$emit('getData', null, null)
    },

    /** 搜索方法 */
    filterStation (query) {
      if (this.city.length == 0) {
        this.$message.warning("请先选择所在城市")
        this.options = []
        return
      }
      if (query !== '') {
        this.loading = true
        setTimeout(() => {
          this.loading = false
          let data = { stationName: query, city: this.city[1] }
          querySiteFuzzy(data).then((res) => {
            if (res.code === 1000) {
              this.options = res.data
            }
          })
        }, 200)
      } else {
        this.options = []
      }
    },
    handleSelect () {
      let value = this.options.find(item => item[this.label] === this.value)
      this.$emit('getData', value ? { stationName: value.name, stationId: value.id, } : null)
    }
  },
  watch: {
    choosedStation (val, oldVal) {
      if (val) {
        this.value = this.choosedStation
      }
    },
    "stationList.length" () {
      this.options = this.stationList

    },
  }
}
</script>
